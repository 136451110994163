<template>
  <div class="main">
    <h2 class="tittle">logo加光阴效果</h2>
    <div class="logo">
      <img src="../../../../assets/images/demo/logo.png" />
    </div>
    <div class="rollWrap">
      <div class="rolled"></div>
    </div>

    <h2 class="tittle" style="margin-top: 200px">通用遮照</h2>
    <span class="btnGreen" @click="play1"> 下拉开始 </span>
    <div class="box box1">bla bla bla</div>

    <span class="btnGreen" @click="play2"> 右拉开始 </span>
    <div class="box box2">bla bla bla</div>

    <h2 class="tittle" style="margin-top: 200px">一根立体的柱子</h2>
      <span class="btnGreen" @click="play3"> 开始 </span>
    <div class="bar">
      <div class="bar-foreground"></div>
      <div class="bar-side"></div>
      <div class="wrapTop"><div class="bar-top"></div></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "blingBoxDemo",
  mounted() {
    $(".rolled").addClass("slideRound");
  },
  methods: {
    play1() {
      $(".box1").css("opacity", 1);
      $(".box1").addClass("shadowSlideDown");
    },
    play2() {
      $(".box2").css("opacity", 1);
      $(".box2").addClass("shadowSlideRight");
    },
    play3() {
      $(".bar-foreground").addClass("barHeight");
      $(".bar-side").addClass("barHeight");
      $(".wrapTop").addClass("barTopMove");
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  min-height: 100%;
  background: #fff;
}
.tittle {
  font-size: 24px;
  margin: 25px;
}
.box {
  width: 100px;
  height: 100px;
  background: #fcd3d3;
  font-size: 24px;
  margin: 25px;
  position: absolute;
  opacity: 0;
}
.box2 {
  left: 150px;
}
.btnGreen {
  background: #447746;
  color: #fff;
  padding: 5px 10px;
  display: inline-block;
  margin-left: 30px;
  font-size: 24px;
}
//logo加光阴效果
.logo {
  position: absolute;
  top: 70px;
  left: 10px;
  width: 268px;
  height: 144px;
}
.rollWrap {
  width: 268px;
  height: 144px;
  position: absolute;
  border-radius: 30px;
  top: 70px;
  left: 10px;
  overflow: hidden;
}
.rolled {
  position: absolute;
  left: 0;
  width: 80px;
  height: 200px;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-35deg);
  overflow: hidden;
}
.slideRound {
  animation: slideRoundAnimate 4s 0.2s ease both infinite;
}
@keyframes slideRoundAnimate {
  75% {
    left: 200px;
  }
  100% {
    left: 550px;
  }
}

//通用遮照
//下拉
.shadowSlideDown::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  animation: shadowSlideDownAnimate 2s;
}
@keyframes shadowSlideDownAnimate {
  0% {
    opacity: 1;
    height: 100%;
  }
  100% {
    height: 0;
    opacity: 1;
  }
}

//右拉
.shadowSlideRight::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  animation: shadowSlideRightAnimate 2s;
}
@keyframes shadowSlideRightAnimate {
  0% {
    opacity: 1;
    width: 100%;
  }
  100% {
    width: 0;
    opacity: 1;
  }
}

// 一根立体的柱子
.bar {
  position: relative;
  height: 430px;
  top: 20px;
}
.bar-foreground {
  position: absolute;
  left: 250px;
  bottom: 65px;
  background: #fa6400;
  width: 40px;
  height: 50px;
  transform: skewY(15deg);
}
.bar-side {
  position: absolute;
  left: 290px;
  bottom: 65px;
  background: #be4d01;
  width: 40px;
  height: 50px;
  transform: skewY(-15deg);
}
.wrapTop {
  position: absolute;
  top: 280px;
  left: 260px;
  transform: scaleY(0.5);
}
.bar-top {
  background: #ff8433;
  width: 60px;
  height: 60px;
  transform: rotate(43deg);
}
.barHeight {
  animation: barHeightAnimate 1s;
  animation-fill-mode: both;
}
@keyframes barHeightAnimate {
  100% {
    height: 350px;
  }
}
.barTopMove {
  animation: barTopMoveAnimate 1s;
  animation-fill-mode: both;
}
@keyframes barTopMoveAnimate {
  100% {
    top: -20px;
  }
}
</style>